/* Contact.css */
.contact {
    padding: 20px;
    text-align: center;
  }
  
  .contact-buttons {
    display: flex;
    flex-direction: row; /* Change this to row for side-by-side alignment */
    justify-content: center; /* Center the buttons horizontally */
    gap: 20px; /* Add space between the buttons */
    flex-wrap: wrap; /* Allow buttons to wrap on smaller screens */
  }
  
  .button {
    display: inline-block;
    padding: 12px 24px;
    background-color: #004ba0;
    color: white;
    text-decoration: none;
    font-size: 1.1rem;
    font-weight: bold;
    border-radius: 30px;
    border: 2px solid transparent;
    transition: all 0.3s ease;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    margin: 10px;
    cursor: pointer;
  }
  
  .button:hover {
    background-color: white;
    color: #004ba0;
    border: 2px solid #004ba0;
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.3);
    transform: translateY(-2px);
  }