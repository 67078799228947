/* Home Page Styles */
.home {
    padding: 50px;
  }
  
  .home-content {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px; /* Adds space between the image and the text */
    flex-wrap: wrap; /* Allows the elements to wrap on smaller screens */
    width: 100%; /* Make sure it spans the full width */
    max-width: 1200px;
    margin: 0 auto; /* Center the content */
  }
  
  .headshot-container {
    flex-shrink: 0;
  }
  
  .headshot {
    width: 300px; /* Make the image larger on desktop */
    height: 300px;
    border-radius: 50%;
    border: 5px solid #ffffff;
  }
  
  .text-container {
    max-width: 600px;
    text-align: left;
    width: 100%; /* Make the text container span the full width */
  }
  
  .text-container h1 {
    font-size: 2.5rem;
    color: white;
  }
  
  .text-container p {
    font-size: 1.2rem;
    color: #ffffff;
    margin-top: 10px;
  }
  
  /* Responsive Styles */
  @media only screen and (max-width: 768px) {
    .home-content {
      flex-direction: column; /* Stack the headshot and text */
      text-align: center; /* Center the text */
    }
  
    .headshot {
      width: 200px; /* Adjust the image size for mobile */
      height: 200px;
    }
  
    .text-container {
      text-align: center;
      width: 100%; /* Center the text and image */
    }
  
    .text-container h1 {
      font-size: 2rem;
    }
  
    .text-container p {
      font-size: 1rem;
    }
  }