/* General Container Styling */
.container {
    background-color: #e0efff;
    padding: 20px;
    border-radius: 15px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    margin: 20px auto;
    max-width: 1200px;
    color: #004ba0;
    font-size: 0.9rem; /* Smaller text for all sections */
  }
  
  .section-title {
    color: #003580;
    text-align: center;
    margin-bottom: 20px;
  }
  
  /* Section Containers - Apply Lighter Background to All Sections */
  .section-container {
    background-color: #f0f7ff; /* Consistent light blue background for all sections */
    padding: 20px;
    margin-bottom: 30px;
    border-radius: 15px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  }
  
  /* Experience, Education, Certificates Items */
  .experience-item, .education-item, .certificate-item {
    padding: 15px;
    background-color: #f8f8f8;
    border-radius: 8px;
    margin-bottom: 20px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
  }
  
  .experience-item h4, .education-item h4 {
    color: #003580;
    margin-bottom: 10px;
  }
  
  .experience-item p, .education-item p {
    color: #004ba0;
  }
  
  /* Two-column layout for Skills and Hobbies */
  .two-column {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }
  
  .skills-column, .hobbies-column {
    background-color: #f8f8f8;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
  }
  
  .skills-list, .hobbies-list {
    list-style-type: none;
    padding-left: 0;
  }
  
  .skills-list li, .hobbies-list li {
    padding: 10px;
    margin-bottom: 10px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
  }